export const errorMessages = {
  401: 'Your session expired. \n Please refresh the page,\n or reach out to out Help Center.',
  403: 'We are missing your permissions to view this page. \n Please try again later,\n or reach out to our Help Center.',
  404: 'Page not found. \n Please try searching again,\n or try one of the links below:',
  200: 'There is no data for this property yet. \n Please try again later,\n or reach out to our Help Center.',
  default: 'This page isn\'t working.\n Try checking back later while we try to fix this issue,\n or reach out to our Help Center',
};

export const errorLinks = (baseUrl) => {
  const linkBaseUrl = baseUrl.replace(/^https?\:\/\//i, '');
  return {
    // [`${linkBaseUrl}/summary`]: `${baseUrl}/summary`,
    [`${linkBaseUrl}/robots`]: `${baseUrl}/robots`,
    [`${linkBaseUrl}/settings`]: `${baseUrl}/settings`,
    // [`${linkBaseUrl}/property`]: `${baseUrl}/property`,
  };
};
