import {
  AccountCircleRounded,
  HelpCenterRounded,
  LogoutRounded,
} from '@mui/icons-material';
import {
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  anchorEl: null | HTMLElement,
  open: boolean,
  onClose: any,
  logout: any,
};

const UserMenuComponent = (props: Props) => {
  const {
    anchorEl,
    open,
    onClose,
    logout,
  } = props;

  return (
    <Menu
      disableAutoFocusItem
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none',
          WebkitBoxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
          MozBoxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
          width: '12rem',
          padding: '0.25rem',
          borderRadius: '8px',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MenuItem
        component={NavLink}
        to="/settings/profile"
        sx={{
          paddingTop: '0.5rem',
          paddingBottom: '0.75rem',
        }}
      >
        <ListItemIcon>
          <AccountCircleRounded sx={{ color: (theme) => theme.palette.text.primary }} />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => logout()}
        sx={{
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      >
        <ListItemIcon>
          <LogoutRounded sx={{ color: (theme) => theme.palette.secondary.main }} />
        </ListItemIcon>
        <ListItemText sx={{ color: (theme) => theme.palette.secondary.main }}>Sign out</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default UserMenuComponent;
