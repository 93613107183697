import {
  Grid, Link, Typography, useMediaQuery,
} from '@mui/material';
import { RenderIf } from '@tailosinc/bender-ui-common';
import { DESKTOP_BREAKPOINT } from '@tailosinc/bender-utils';
import React from 'react';

import SecondaryButton from '../SecondaryButton';
import { errorLinks, errorMessages } from './constant';

type ButtonProps = {
  buttonFunction: () => void
  label: string
};

interface Props {
  statusCode?: number,
  baseUrl: string
  customMessage?: string,
  customButton?: ButtonProps
}

const ErrorComponent = (props: Props) => {
  const {
    statusCode,
    customMessage,
    customButton,
    baseUrl,
  } = props;

  const messageSelector = (code: any, message: string) => {
    if (message) {
      return message;
    }
    return errorMessages[code] ? errorMessages[code] : errorMessages.default;
  };

  const message = messageSelector(statusCode, customMessage);

  const links = Object.entries(errorLinks(baseUrl || 'https://app.tailos.io')).map(([key, value]) => (<Link key={key} href={`${value}`} fontSize='1.25rem'>{key}</Link>));

  return (
    <Grid
      container
      direction="column"
      // justifyContent="center"
      alignItems="flex-start"
      sx={{
        height: '100vh',
        backgroundImage: `url('${process.env.GLOBAL_ASSETS_URL}/Rosie-AI-01.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: !DESKTOP_BREAKPOINT(useMediaQuery) ? '17rem 17rem' : '25rem 25rem',
        backgroundPositionX: !DESKTOP_BREAKPOINT(useMediaQuery) ? '-3rem' : '-5rem',
        backgroundPositionY: !DESKTOP_BREAKPOINT(useMediaQuery) ? '-3rem' : '-5rem',
      }}
    >
      <Grid
        item
        sx={{
          marginTop: !DESKTOP_BREAKPOINT(useMediaQuery) ? '10rem' : '11rem',
          marginLeft: !DESKTOP_BREAKPOINT(useMediaQuery) ? '2rem' : '12rem',
          marginBottom: '0.5rem',
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontSize: '2.25rem',
          }}
        >
          Sorry
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: !DESKTOP_BREAKPOINT(useMediaQuery) ? '2rem' : '12rem',
          marginBottom: '2rem',
        }}
      >
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            fontSize: '1.25rem',
          }}
          color={(theme) => theme.palette.text.primary}
        >
          { message }
        </Typography>
        <RenderIf isTrue={statusCode === 404}>
          <Grid
            container
            direction='column'
          >
            {links}
          </Grid>
        </RenderIf>
      </Grid>
      <Grid
        item
        sx={{
          display: !DESKTOP_BREAKPOINT(useMediaQuery) ? 'block' : 'flex',
          marginLeft: !DESKTOP_BREAKPOINT(useMediaQuery) ? '2rem' : '12rem',
        }}
      >
        <SecondaryButton
          buttonAction={customButton && customButton.buttonFunction}
          buttonText={customButton ? customButton.label : 'Home'}
          size='large'
          href={!customButton ? `${baseUrl}/` : null}
          additionalStyling={{
            width: 'fit-content',
            marginRight: '1rem',
            marginBottom: '1rem',
          }}
        />
        <Link href='https://support.tailos.com/' target='_blank' rel='noreferrer'>
          <SecondaryButton
            buttonAction={() => {}}
            buttonText='Help Center'
            size='large'
            additionalStyling={{
              width: 'fit-content',
              marginBottom: '1rem',
            }}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default ErrorComponent;
