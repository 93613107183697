/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-dom-props */
import { Box, PaletteMode } from '@mui/material';
import {
  styled, ThemeProvider,
} from '@mui/material/styles';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import { checkForAppChanges } from '@tailosinc/bender-core';
import { darkTheme, lightTheme } from '@tailosinc/bender-theme';
import { RenderIf } from '@tailosinc/bender-ui-common';
import React, {
  useEffect, useState,
} from 'react';
import {
  Outlet, useLocation, useNavigate,
} from 'react-router-dom';

import { setTheme } from '../../actions';
import SiteHeader from '../../containers/SiteHeader';
import store from '../../store';
import ConfirmationDialog from '../ConfirmationDialog';
import ErrorComponent from '../ErrorComponent';
import Logout from '../Logout';
import useInactivityUtils from './inactivity';
import LoadingSkeleton from './LoadingSkeleton';
// import useScript from './useScript';

type Props = {
  activeSidebar: any,
  logoutRequested: boolean,
  propertyListError: any,
};
const ContentSeparator = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const App = (props: Props) => {
  const {
    activeSidebar,
    logoutRequested,
    propertyListError,
  } = props;
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const [openDrawer, setDrawerOpen] = useState(true);
  const {
    timer, openSessionEndingDialog, handleClose, handleSignOut,
  } = useInactivityUtils();

  const statusCode = JSON.parse(propertyListError)?.networkError?.statusCode;

  const checkStatus = async () => {
    const status = await checkForAppChanges();
    if (status.length > 0 || location.pathname === '/auth' || location.pathname === '/page-not-found') {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/robots');
    }
    setIsLoading(true);
    checkStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  // useScript('ze-snippet', 'https://static.zdassets.com/ekr/snippet.js?key=a4d53842-ad81-4ab2-8b70-b0b2ad7a1b92');
  store.dispatch(setTheme('theme', { theme: mode }));

  return (
    <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <SiteHeader
          openDrawer={openDrawer}
          setDrawerOpen={setDrawerOpen}
        />
        <div id="notification" />
      </Box>
      {
        logoutRequested && <Logout onLoad={auth.signOut} />
      }
      <ContentSeparator />
      <RenderIf isTrue={isLoading}>
        <Box
          component='main'
          sx={{
            height: '100%',
            overflow: 'auto',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            ...(openDrawer === true && {
              marginLeft: '240px',
            }),
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <LoadingSkeleton />
        </Box>
      </RenderIf>
      <RenderIf isTrue={propertyListError}>
        <Box
          component='main'
          sx={{
            height: '100%',
            overflow: 'auto',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            ...(openDrawer === true && {
              marginLeft: '240px',
            }),
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <ErrorComponent
            statusCode={statusCode}
            baseUrl={window.location.origin}
          />
        </Box>
      </RenderIf>
      <RenderIf isTrue={!propertyListError}>
        <Box
          component='main'
          sx={{
            height: '100%',
            overflow: 'auto',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            ...(openDrawer === true && {
              marginLeft: '240px',
            }),
            backgroundColor: (theme) => theme.palette.background.default,
          }}
          id="main"
        >
          <Outlet />
        </Box>
      </RenderIf>
      <ConfirmationDialog
        open={openSessionEndingDialog}
        handleClose={handleClose}
        buttonAction={handleSignOut}
        dialogTitleMessage='Are you still there?'
        dialogContentMessage={`You will be signed out in ${timer} seconds due to inactivity.`}
        actionButtonText='Sign Out'
        closeButtonText='Stay Signed In'
      />
    </ThemeProvider>
  );
};

export default App;
