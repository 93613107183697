import {
  Box,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import DynamicIcon from './DynamicIcon';

type Props = {
  listItems: { title: string, appIcon: string, link: string, target?: string, rel?: string }[],
  isMobileOpen?: boolean,
};

const TabletList = (props: Props) => {
  const {
    listItems,
    isMobileOpen,
  } = props;
  const location = useLocation();
  const theme = useTheme();
  const getHightlight = () => {
    let highlight;
    if (theme.palette.mode === 'light') {
      highlight = theme.palette.highlight_on_primary.main;
    } else {
      highlight = theme.palette.highlight_on_paper.main;
    }
    return highlight;
  };

  return (
    <>
      {
        listItems.map((item) => (
          <Box key={item.title}>
            <ListItem
              component={['Support', 'Tailos Store'].includes(item.title) ? Link : NavLink}
              to={item.link}
              href={item.link}
              target={item.target}
              rel={item.rel}
              sx={{
                width: '200px',
                marginLeft: '1rem',
                marginRight: '1rem',
                borderRadius: '4px',
                color: theme.palette.common.white,
                ...(location.pathname.startsWith(item.link) && {
                  backgroundColor: getHightlight(),
                }),
                ...(isMobileOpen && {
                  display: 'block',
                  margin: '0rem',
                  textAlign: 'center',
                  width: 'fit-content',
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  ...(isMobileOpen && {
                    justifyContent: 'center',
                  }),
                }}
              >
                <DynamicIcon
                  appIcon={item.appIcon}
                  isMobileOpen={isMobileOpen}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: isMobileOpen ? '1rem' : '1.25rem' }}
                sx={{
                  ...(isMobileOpen && {
                    marginTop: '0rem',
                    marginBottom: '0rem',
                  }),
                }}
              >
                {item.title}
              </ListItemText>
            </ListItem>
          </Box>
        ))
      }
    </>
  );
};

export default TabletList;
