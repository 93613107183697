import { LOAD_PROPERTY_LIST_ERROR, LOAD_PROPERTY_LIST_START, LOAD_PROPERTY_LIST_SUCCESS } from './types';

export const loadPropertyList = (loaderFn: any, force: any) => async (dispatch: any, getState: any) => {
  const { propertyList, propertyListLoading, propertyListError } = getState();
  // don't load if already loading
  // load if force, or if there was an error previously, or if there is no list yet
  if (!propertyListLoading && (force || propertyListError || propertyList == null)) {
    dispatch({
      type: LOAD_PROPERTY_LIST_START,
    });
    let propertyListData;
    try {
      propertyListData = await loaderFn();
      if (propertyListData.error) {
        dispatch({
          type: LOAD_PROPERTY_LIST_ERROR,
          data: propertyListData,
        });
        return undefined;
      }
    } catch (err: any) {
      dispatch({
        type: LOAD_PROPERTY_LIST_ERROR,
        data: JSON.stringify(err),
      });
      return undefined;
    }
    dispatch({
      type: LOAD_PROPERTY_LIST_SUCCESS,
      data: propertyListData,
    });
    return propertyListData;
  }
  return undefined;
};

export default loadPropertyList;
