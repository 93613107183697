import 'isomorphic-fetch';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const getClient = (auth: { jwtToken: any; }) => {
  const httpLink = createHttpLink({ uri: process.env.NOTIFICATION_ENDPOINT });
  const authMiddleware = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: auth,
    },
  }));

  const httpLinkWithAuthToken = authMiddleware.concat(httpLink);
  return new ApolloClient({
    link: httpLinkWithAuthToken,
    cache: new InMemoryCache(),
  });
};

export default getClient;
