import { AccountCircleRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import React, { useState } from 'react';

import UserMenu from '../../containers/UserMenu';
import { decodePayload } from '../../utils';

const UserProfileAvatar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const auth = useAuth();
  const user = decodePayload(auth.getJwtToken());

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        component='div'
        onClick={(e) => {
          handleClick(e);
        }}
        sx={{
          display: 'flex',
          position: 'absolute',
          bottom: '0px',
          height: '65px',
          justifyContent: 'center',
          borderTopColor: (theme) => theme.palette.divider,
          borderTopStyle: 'solid',
          alignItems: 'center',
          width: '239px',
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <AccountCircleRounded
          sx={{
            color: (theme) => theme.palette.common.white,

          }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.common.white,
            fontSize: '1.25rem',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
          }}
        >
          {`${user.given_name} ${user.family_name}`}
        </Typography>
        <KeyboardArrowDownRounded
          sx={{
            color: (theme) => theme.palette.common.white,
          }}
        />
      </Box>
      <UserMenu
        visible
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default UserProfileAvatar;
