import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const LoadingSkeleton = () => (
  <Stack direction='row' justifyContent='space-between' sx={{ margin: '1rem' }}>
    <Skeleton
      animation='wave'
      variant='rectangular'
      width='20vw'
      height='80vh'
    />
    <Skeleton
      animation='wave'
      variant='rectangular'
      width='100vh'
      height='80vh'
    />
  </Stack>
);

export default LoadingSkeleton;
