import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

type Props = {
  buttonAction: any,
  buttonText: string,
  disabledState?: boolean
  errorState?: boolean,
  additionalStyling?: any,
  size?: 'small' | 'medium' | 'large',
  href?: string | null,
};
const SecondaryButton = (props: Props) => {
  const {
    buttonAction,
    buttonText,
    disabledState,
    errorState,
    additionalStyling,
    size,
    href,
  } = props;
  const theme = useTheme();

  return (
    <Button
      variant='contained'
      disabled={errorState || disabledState}
      size={size}
      href={href}
      onClick={() => {
        buttonAction();
      }}
      sx={{
        'color': theme.palette.text.primary,
        'backgroundColor': theme.palette.background.paper,
        'borderRadius': '4px',
        'width': '90%',
        'border': `1px solid ${theme.palette.text.disabled}`,
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        ...additionalStyling,
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SecondaryButton;
