import { Grid, Typography } from '@mui/material';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import React from 'react';

const AuthPage = () => {
  const auth = useAuth();
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h3">
          Role &amp; Permissions
        </Typography>
      </Grid>
      <Grid item>
        <pre>
          {JSON.stringify(auth.getRoles(), null, 2)}
        </pre>
      </Grid>
      <Grid item>
        <Typography variant="h3">
          ID Token
        </Typography>
      </Grid>
      <Grid item>
        <pre>
          {JSON.stringify(auth.getIdToken(), null, 2)}
        </pre>
      </Grid>
      <Grid item>
        <Typography variant="h3">
          Refresh Token
        </Typography>
      </Grid>
      <Grid item>
        <pre>
          {JSON.stringify(auth?.user?.signInUserSession?.refreshToken?.token, null, 2)}
        </pre>
      </Grid>
      <Grid item>
        <Typography variant="h3">
          Access Token
        </Typography>
      </Grid>
      <Grid item>
        <pre>
          {JSON.stringify(auth.getAccessToken(), null, 2)}
        </pre>
      </Grid>
    </Grid>
  );
};

export default AuthPage;
