import { Drawer } from '@mui/material';
import React from 'react';

import DrawerContent from './drawerContent';

type Props = {
  bender: any,
  open: boolean,
  isMobileOpen: boolean,
  setIsMobileOpen: any,
  handleDrawerToggle: any,
  MOBILE_BREAKPOINT: boolean,
};

const NavSidebar = (props: Props) => {
  const {
    bender,
    open,
    isMobileOpen,
    setIsMobileOpen,
    handleDrawerToggle,
    MOBILE_BREAKPOINT,
  } = props;

  if (MOBILE_BREAKPOINT && !isMobileOpen) {
    handleDrawerToggle();
    setIsMobileOpen(true);
  }
  if (!MOBILE_BREAKPOINT && isMobileOpen) {
    handleDrawerToggle();
    setIsMobileOpen(false);
  }

  return (
    <Drawer
      sx={{
        'width': 240,
        'flexShrink': 0,
        'backgroundColor': (theme) => theme.palette.primary.main,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }}
      variant={isMobileOpen ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
    >
      <DrawerContent
        bender={bender}
      />
    </Drawer>
  );
};

export default NavSidebar;
