import { SELECTED_THEME } from './types';

// eslint-disable-next-line import/prefer-default-export
export const setTheme = (name: any, data: any) => ({
  type: SELECTED_THEME,
  data: {
    name,
    custom: { ...data },
  },
});
