import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import * as bender from '@tailosinc/bender-core';
import { useMemo, useRef, useState } from 'react';

const useInactivityUtils = () => {
  const timerRef = useRef<any>(null);
  const [timer, setTimer] = useState('00');
  const [openSessionEndingDialog, setOpenSessionEndingDialog] = useState(false);
  const auth = useAuth();
  // token expiration is 1 hour. 2700000ms is 45 minutes
  const expirationTime = 2700000;
  let intervalId: any;

  const handleSignOut = () => {
    clearInterval(intervalId);
    intervalId = null;
    clearInterval(timerRef.current);
    setTimer('00');
    setOpenSessionEndingDialog(false);
    auth.signOut();
  };

  const getTimeRemaining = (endTime: Date) => {
    const total = Date.parse(endTime.toString()) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60,
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24,
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (endTime: Date) => {
    const {
      total, hours, minutes, seconds,
    } = getTimeRemaining(endTime);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        `${seconds > 9 ? seconds : `0${seconds}`}`,
      );
    } else {
      handleSignOut();
    }
  };

  const clearTimer = (endTime: Date) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('30');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(endTime);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();
    // This is where you need to adjust if
    // you intend to add more time
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const showDialog = () => {
    bender.unmountApp('notification');
    clearInterval(intervalId);
    intervalId = null;
    setOpenSessionEndingDialog(true);
    onClickReset();
  };

  const handleInactivity = () => {
    if (!intervalId) {
      intervalId = setInterval(showDialog, expirationTime);
    }
  };

  const listenForActivity = () => {
    Object.keys(window).forEach((key) => {
      if (/^on(key|mousedown)/.test(key)
      || /^on(key|touchstart)/.test(key)
    || /^on(key|keydown)/.test(key)
    || /^on(key|scroll)/.test(key)
    || /^on(key|mousemove)/.test(key)) {
        window.addEventListener(key.slice(2), () => {
          clearInterval(intervalId);
          intervalId = null;
          handleInactivity();
        });
      }
    });
  };

  const handleClose = () => {
    clearInterval(timerRef.current);
    setTimer('00');
    handleInactivity();
    setOpenSessionEndingDialog(false);
  };

  const firstRender = useMemo(() => {
    handleInactivity();
    listenForActivity();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    timer,
    openSessionEndingDialog,
    handleClose,
    handleSignOut,
  };
};

export default useInactivityUtils;
