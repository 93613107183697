import { CognitoSecurity } from '@tailosinc/bender-auth-react-cognito-hook';
import * as bender from '@tailosinc/bender-core';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  loadPropertyList,
  loginSuccess,
  setActiveSidebar,
  tokenRefreshed,
  updateSelectedProperty,
} from './actions';
import Root from './containers/Root';
import getPropertyListClientSwitch from './main-apollo-client';
import store from './store';
import propertyListLoader from './utils/propertyListLoader';

const appDom = document.getElementById('mainApp');

console.log('Starting Bender React Main App'); // eslint-disable-line no-console

// set a function to retrieve current data stored in redux
bender.setDataContextGetter(() => cloneDeep(store.getState()));

const onTokenRefreshed = (auth: any) => {
  const { propertyList } = store.getState();
  const userPropertyId = auth.getIdToken().payload.currentProperty || auth.getIdToken().payload['custom:defaultProperty'];
  const userProperty = (propertyList as Array<any>).find((property) => property.id === userPropertyId);
  store.dispatch(tokenRefreshed(auth, userProperty));
};

const onLoginSuccess = async (auth: any) => {
  const { idToken } = auth.user.signInUserSession;
  const data: any = await store.dispatch(
    loadPropertyList(propertyListLoader(getPropertyListClientSwitch()(auth)), true));
  const userPropertyId = idToken.payload.currentProperty || idToken.payload['custom:defaultProperty'];
  const userProperty = data ? (data as Array<any>).find((property) => property.id === userPropertyId) : null;
  if (userProperty) {
    store.dispatch(updateSelectedProperty(userProperty));
  }
  store.dispatch(loginSuccess(auth, userProperty));
  store.dispatch(setActiveSidebar('sideNavBar', { isOpen: true }));
};

const awsConfig = {
  Auth: {
    userPoolId: process.env.COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    region: process.env.COGNITO_REGION || 'us-east-1',
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};

const MainApp = () => (
  <Provider store={store}>
    <CognitoSecurity
      awsConfig={awsConfig}
      onLoginSuccess={onLoginSuccess}
      onTokenRefreshed={onTokenRefreshed}
    >
      <Root />
    </CognitoSecurity>
  </Provider>
);

const renderUI = () => {
  // Render the main app react component into the app div.
  ReactDOM.render(<MainApp />, appDom);
};

renderUI();

export default MainApp;
