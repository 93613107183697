import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import {
  Backdrop,
  Badge,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { common } from '@mui/material/colors';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import { getAppStatus } from '@tailosinc/bender-core';
import { RenderIf } from '@tailosinc/bender-ui-common';
import React, { useEffect, useState } from 'react';

import getClientSwitch from '../../notification-apollo-client';
import notificationLoader from '../../utils/notificationLoaders';

type Props = {
  microFrontEnd: any,
};

const NotificationIcon = (props: Props) => {
  const {
    microFrontEnd,
  } = props;
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isNotificationActive, setIsNotificationActive] = useState<boolean>(false);

  const auth = useAuth();

  const checkStatus = () => {
    const status = getAppStatus('notification');
    if (status === 'MOUNTED') {
      setNotificationLoading(false);
      setIsNotificationActive(true);
    } else {
      setTimeout(checkStatus, 1000);
    }
  };

  const toggleDisplay = () => {
    if (isNotificationActive) {
      microFrontEnd.unmountApp('notification');
      setIsNotificationActive(false);
      return;
    }
    microFrontEnd.renderApp('notification');
    setIsNotificationActive(true);
    setNotificationLoading(true);
    checkStatus();
  };

  const notificationsLabel = (count: number) => {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  };

  const handleEsc = (event:any) => {
    if (event.key === 'Escape' && isNotificationActive) {
      toggleDisplay();
    }
  };

  const loadNotifications = async (loaderFn: any) => {
    let notificationData;
    try {
      notificationData = await loaderFn();
    } catch (err: any) {
      return undefined;
    }
    return notificationData;
  };

  const fetchData = async () => {
    const data: any = await loadNotifications(notificationLoader(getClientSwitch()(auth.getJwtToken())));
    checkStatus();
    localStorage.setItem('notifications', JSON.stringify(data));
    setNotificationCount(data.length);
  };

  useEffect(() => {
    fetchData();
    window.addEventListener('storage', () => {
      const localNotifications = JSON.parse(localStorage.getItem('notifications'));
      if (!localNotifications) {
        setNotificationCount(0);
      } else {
        setNotificationCount(localNotifications.length);
      }
    }, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderIf isTrue={!notificationLoading}>
        <IconButton
          aria-label={notificationsLabel(notificationCount)}
          onClick={toggleDisplay}
          onKeyDown={(e) => handleEsc(e)}
          sx={{
            'width': '3rem',
            'height': '3rem',
            'color': common.white,
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Badge
            color='error'
            badgeContent={notificationCount}
          >
            <NotificationsRoundedIcon />
          </Badge>
        </IconButton>
      </RenderIf>
      <RenderIf isTrue={notificationLoading}>
        <CircularProgress
          sx={{
            color: (theme) => theme.palette.highlight_on_paper.main,
          }}
        />
      </RenderIf>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isNotificationActive}
        onClick={toggleDisplay}
      />
    </>
  );
};

export default NotificationIcon;
