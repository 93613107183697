import gql from 'graphql-tag';

export default gql`
  query getNotificationsForUser {
    notifications {
      notificationId
      notificationName
      timeElapsed
      description
      actionLink
    }
  }
`;
