/* eslint-disable react/forbid-component-props */
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import { RenderIf } from '@tailosinc/bender-ui-common';
import React, {
  Dispatch, SetStateAction, useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { setActiveSidebar } from '../../actions';
import PropertyPickerDropdownContainer from '../../containers/PropertyPickerDropdown';
import store from '../../store';
import { getPermissions } from '../../utils';
import MobileAppBar from '../MobileAppBar';
import NavSidebar from '../NavSidebar';

type Props = {
  bender: any,
  openDrawer: boolean,
  setDrawerOpen: Dispatch<SetStateAction<boolean>>,
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const SiteHeaderComponent = (props: Props) => {
  const {
    bender,
    openDrawer,
    setDrawerOpen,
  } = props;
  const location = useLocation();
  const auth = useAuth();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const MOBILE_BREAKPOINT = useMediaQuery('(max-width:990px)');

  const drawerWidth = 240;
  const navBarTitle = location.pathname.split('/')[1];

  const handleDrawerToggle = () => {
    if (!openDrawer && MOBILE_BREAKPOINT && !isMobileOpen) {
      return;
    }
    if (openDrawer && !MOBILE_BREAKPOINT && isMobileOpen) {
      return;
    }
    store.dispatch(setActiveSidebar('sideNavBar', { isOpen: !openDrawer }));
    setDrawerOpen(!openDrawer);
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const theme = useTheme();

  const microAppsMetadataDrawer = bender.getAppsForMountPoint('drawer', {
    roles: getPermissions(auth.getRoles()),
  }).sort((a: any, b: any) => a.order - b.order);

  // const microAppsMetadataTopNavIcons = bender.getAppsForMountPoint('top-nav-icons', {
  //   roles: getPermissions(auth.getRoles()),
  // }).sort((a: any, b: any) => a.order - b.order);

  // // eslint-disable-next-line no-unused-vars
  // const [navCategory, setNavCategory] = useState();
  // // eslint-disable-next-line no-unused-vars
  // const [activeLink, setActiveLink] = useState(null); // this is for later
  // // if we have sub menu options and want to dispaly them at the top.

  useEffect(() => {
    const changeEventCallback = () => {
      const activeItem = location.pathname.length > 1
        && microAppsMetadataDrawer.filter((item: any) => location.pathname.startsWith(item.link))[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      activeItem && (
        setNavCategory(activeItem.category),
        setActiveLink(activeItem.link)
      );
    };
    window.addEventListener(bender.APP_CHANGE_EVENT, changeEventCallback);
    window.addEventListener(bender.NO_APP_CHANGE_EVENT, changeEventCallback);
    changeEventCallback();

    let returnValue;
    if (window.removeEventListener(bender.APP_CHANGE_EVENT, changeEventCallback) !== undefined) {
      returnValue = window.removeEventListener(bender.APP_CHANGE_EVENT, changeEventCallback);
    } else {
      returnValue = window.removeEventListener(bender.NO_APP_CHANGE_EVENT, changeEventCallback);
    }
    return returnValue;
  }, [location.pathname, microAppsMetadataDrawer, bender.APP_CHANGE_EVENT, bender.NO_APP_CHANGE_EVENT]);

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
    document.body.style.overflow = 'hidden';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={openDrawer}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.background.paper,
            height: '65px',
          }}
        >
          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                aria-label="open drawer"
                onClick={() => {
                  handleDrawerToggle();
                }}
                edge="start"
                sx={{
                  width: '2rem',
                  height: '2rem',
                  color: theme.palette.text.primary,
                }}
              >
                <MenuIcon sx={{ fontSize: '24px' }} />
              </IconButton>
              <Typography
                sx={{
                  marginLeft: '1rem',
                  fontSize: '1.25rem',
                  color: theme.palette.text.primary,
                  textTransform: 'capitalize',
                }}
              >
                {navBarTitle}
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              <PropertyPickerDropdownContainer />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <NavSidebar
        bender={bender}
        open={openDrawer}
        isMobileOpen={isMobileOpen}
        setIsMobileOpen={setIsMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        MOBILE_BREAKPOINT={MOBILE_BREAKPOINT}
      />
      <RenderIf isTrue={isMobileOpen}>
        <MobileAppBar
          isMobileOpen={isMobileOpen}
          openDrawer={openDrawer}
        />
      </RenderIf>
    </Box>
  );
};

export default SiteHeaderComponent;
