import {
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '@tailosinc/bender-auth-react-cognito-hook';
import { DropDown } from '@tailosinc/bender-ui-common';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { USER_PERM } from '../../utils/constants';
import useStyles from './styles';

interface PropertyPickerDropdownProps {
  loadPropertyList: (a:any, b:any) => void
  onPropertyChange: (a: any) => void
  loading: boolean
  properties: any
  property: any
}

const PropertyPickerDropdown = (props: PropertyPickerDropdownProps) => {
  const {
    loadPropertyList,
    onPropertyChange,
    loading,
    properties,
    property,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const theme = useTheme();

  const handlePropertyChange = (id: string) => {
    const selectedProperty = properties.find((p: any) => p.id === id);
    onPropertyChange(selectedProperty);
  };

  if (loading) {
    return null;
  }

  const onlyOneProperty = !(properties?.length > 1);
  const dropDownOptions = properties?.map((p: any) => ({ value: p.id, label: p.name }));
  const selectedProertyId = property.id;

  if (onlyOneProperty) {
    return (
      <Chip
        className={classes.chip}
        label={property?.name}
      />
    );
  }

  if (isEmpty(property)) {
    return null;
  }

  const getStyle = () => {
    let style;
    if (theme.palette.mode === 'light') {
      style = {
        'borderRadius': '4px',
        'height': '2.5rem',
        'margin': '1rem',
        'fontSize': '1.25rem',
        '&:hover': {
          '&& fieldset': {
            border: `3px solid ${theme.palette.secondary.main}`,
          },
        },
      };
    } else {
      style = {
        'borderRadius': '4px',
        'height': '2.5rem',
        'margin': '1rem',
        'fontSize': '1.25rem',
        'backgroundColor': theme.palette.background.paper,
        '&:hover': {
          '&& fieldset': {
            border: `3px solid ${theme.palette.secondary.main}`,
          },
        },
      };
    }
    return style;
  };

  return (
    <div>
      <DropDown
        initialOption={selectedProertyId}
        options={dropDownOptions}
        handleSelect={handlePropertyChange}
        styles={getStyle()}
      />
    </div>
  );
};

export default PropertyPickerDropdown;
