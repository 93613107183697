import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import SecondaryButton from '../SecondaryButton';

type Props = {
  open: boolean,
  handleClose: any,
  buttonAction: any,
  dialogTitleMessage: string,
  dialogContentMessage: string,
  actionButtonText: string,
  closeButtonText?: string,
};

const ConfirmationDialog = (props: Props) => {
  const {
    open,
    handleClose,
    buttonAction,
    dialogTitleMessage,
    dialogContentMessage,
    actionButtonText,
    closeButtonText,
  } = props;
  const theme = useTheme();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      data-testid="confirmation-dialog"
    >
      <DialogTitle>
        {dialogTitleMessage}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          '&.MuiDialogContent-dividers': {
            borderColor: theme.palette.highlight_on_paper.main,
          },
        }}
      >
        {dialogContentMessage}
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          buttonAction={handleClose}
          buttonText={closeButtonText ?? 'Cancel'}
          additionalStyling={{
            width: 'fit-content',
            marginRight: '1rem',
          }}
        />
        <SecondaryButton
          buttonAction={buttonAction}
          buttonText={actionButtonText}
          additionalStyling={{
            width: 'fit-content',
            color: theme.palette.error.main,
            border: `1px solid ${theme.palette.error.main}`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
