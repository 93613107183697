import {
  Box,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import iconSolid from '../../../../assets/global/tailos_full_logo_white.svg';
import DynamicMenu from '../DynamicMenu';
import SecondaryMenu from '../DynamicMenu/SecondaryMenu';
import NotificationIcon from '../NotificationIcon';
import UserProfileAvatar from '../UserProfileAvatar';

type Props = {
  bender: any,
};

const DrawerContent = (props: Props) => {
  const {
    bender,
  } = props;
  const navigate = useNavigate();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: '65px',
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  return (
    <>
      <DrawerHeader>
        <Box
          sx={{
            'cursor': 'pointer',
            'padding': '0.5rem',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          }}
        >
          <Box
            component='img'
            sx={{
              float: 'left',
              height: '0.75rem',
            }}
            alt="Tailos Icon Logo Full Color"
            src={iconSolid}
            onClick={() => {
              navigate('/');
            }}
          />
        </Box>
        <div style={{ display: 'flex' }}>
          <NotificationIcon
            microFrontEnd={bender}
          />

        </div>
      </DrawerHeader>
      <Divider sx={{ color: (theme) => theme.palette.divider }} />
      <DynamicMenu />
      <Divider sx={{
        color: (theme) => theme.palette.divider,
        marginTop: '35vh',
        marginBottom: '0.5rem',
      }}
      />
      <SecondaryMenu />
      <UserProfileAvatar />
    </>
  );
};

export default DrawerContent;
