/* eslint-disable @typescript-eslint/default-param-last */
import { SELECTED_THEME } from '../../actions/selectedTheme/types';

export const emptyState = null;

export default (state = emptyState, action: any) => {
  switch (action.type) {
    case SELECTED_THEME:
      return {
        ...action.data,
      };
    default:
      return state;
  }
};
