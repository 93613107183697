import {
  AppBar,
  Toolbar,
} from '@mui/material';
import React from 'react';

import DynamicMenu from '../DynamicMenu';

type Props = {
  isMobileOpen: boolean,
  openDrawer: boolean,
};
const MobileAppBar = (props: Props) => {
  const { isMobileOpen, openDrawer } = props;
  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        zIndex: (theme) => theme.zIndex.drawer,
        ...(openDrawer && {
          width: 'calc(100% - 240px)',
        }),
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          ...(isMobileOpen && {
            justifyContent: 'center',
          }),
        }}
      >
        <DynamicMenu isMobileOpen={isMobileOpen} />
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
