/* eslint-disable react/forbid-component-props */
import React from 'react';

import TabletList from './TabletList';

const listItems: { title: string, appIcon: string, link: string }[] = [
  // {
  //   title: 'Summary',
  //   appIcon: 'Dashboard',
  //   link: '/summary',
  // },
  {
    title: 'Robots',
    appIcon: 'Robot',
    link: '/robots',
  },
  {
    title: 'Runs',
    appIcon: 'RouteRounded',
    link: '/runs',
  },
];
type Props = {
  isMobileOpen?: boolean,
};
const DynamicMenu = (props: Props) => {
  const {
    isMobileOpen,
  } = props;

  return (
    <TabletList
      listItems={listItems}
      isMobileOpen={isMobileOpen}
    />
  );
};

export default DynamicMenu;
