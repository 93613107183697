/* eslint-disable react/forbid-component-props */
import React from 'react';

import TabletList from './TabletList';

const listItems: { title: string, appIcon: string, link: string, target?: string, rel?: string }[] = [
  {
    title: 'Settings',
    appIcon: 'SettingsRounded',
    link: '/settings',
  },
  {
    title: 'Support',
    appIcon: 'SupportAgentRounded',
    link: 'https://support.tailos.com',
    target: '_blank',
    rel: 'noreferrer',
  },
  {
    title: 'Tailos Store',
    appIcon: 'ShoppingCartRounded',
    link: 'https://shop.tailos.com/',
    target: '_blank',
    rel: 'noreferrer',
  },
];
type Props = {
  isMobileOpen?: boolean,
};
const SecondaryMenu = (props: Props) => {
  const {
    isMobileOpen,
  } = props;

  return (
    <TabletList
      listItems={listItems}
      isMobileOpen={isMobileOpen}
    />
  );
};

export default SecondaryMenu;
