/* eslint-disable react/forbid-component-props */
import * as icons from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import RobotIcon from '../../../../assets/global/New_DON.svg';

type Props = {
  appIcon: string,
  isMobileOpen?: boolean,
};

const DynamicIcon = (props: Props) => {
  const { appIcon, isMobileOpen } = props;
  const IconComponent = (icons as any)[appIcon];
  const theme = useTheme();

  if (appIcon === 'Robot') {
    return (
      <img
        src={RobotIcon}
        alt="robot"
        style={{
          width: isMobileOpen ? '2rem' : '2.5rem',
          height: isMobileOpen ? '2rem' : '2.5rem',
        }}
      />
    );
  }

  return (
    <IconComponent
      sx={{
        color: theme.palette.common.white,
        fontSize: '2rem',
      }}
    />
  );
};

export default DynamicIcon;
